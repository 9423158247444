<template>
    <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
    <div class="relative"
        @focusin="setActive"
        @focusout="setInActive"
        @mouseover="setActive"
        @mouseout="setInActive">
        <div class="grid grid-cols-1 grid-rows-1">
            <div v-for="(item, idx) in items"
                :key="idx"
                :class="idx === currentIndex ? 'opacity-100' : 'opacity-0 -z-10'"
                class="col-start-1 row-start-1 transition-opacity duration-1000 ease-in-out">
                <slot name="item"
                    v-bind="item"></slot>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts" generic="T extends object">
import { useIntervalFn } from '@vueuse/core';

const properties = defineProps<{
    items: T[]
}>();

provide('sb.inWideSlider', true);

const currentIndex = ref(0);

function move(index: number) {
    currentIndex.value = index;

    if (currentIndex.value >= properties.items.length) {
        currentIndex.value = 0;
    }
}

const { pause, resume } = useIntervalFn(() => {
    move(currentIndex.value + 1);
}, 5000);

function setActive() {
    pause();
}

function setInActive() {
    resume();
}
</script>
